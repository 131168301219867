<template>
  <div id="app" class="app">
    <router-view> </router-view>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less">
@import "assets/css/base.less";
body, div, dl, dt, dd, ul, ol, li, p, h1, h2, h3, h4, h5, h6, pre, form, fieldset, input, textarea {
  font-family: Robot, Helvetica, Arial, sans-serif, serif !important;
  //word-break: break-all;
}
</style>